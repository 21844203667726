import {
  ContrastBlock,
  Demo,
  Link,
  MiniBlock,
  Page,
  PrimaryButton,
  ScreenShot,
  SubHero,
} from "@app/site";
import {
  Analytics,
  HeroIllustration,
  NotificationV2,
  SecureSharing,
  Versioning,
} from "@app/site/illustration";
import {
  ArrowRightIcon,
  Box,
  HBox,
  Heading,
  NotionIcon,
  Spacer,
  VBox,
} from "@app/ui";
import { useSmallScreen } from "@app/ui/hooks";
import { SEO } from "@app/ui/seo";
import { LightMode } from "@chakra-ui/color-mode";
import { SimpleGrid } from "@chakra-ui/react";
import { PageProps } from "gatsby";
import * as React from "react";

function IndexPage(props: PageProps) {
  const { isSmallScreen, isMediumScreen } = useSmallScreen();
  return (
    <LightMode>
      <Page>
        <SEO />
        <Hero />
        <Spacer h={10} />
        <SubHero />
        <Spacer h={10} />
        <Demo />

        <Spacer h={160} />

        <ContrastBlock
          title="Built-in & Advanced Analytics"
          subtitle={
            <>
              <ArrowRightIcon m={2} boxSize={3} />
              At your Notion page content level — no code required.
              {/* <Link
                ml={isSmallScreen ? 0 : 2}
                to="/signup"
                bg={"cyan.100"}
                _hover={{
                  textDecoration: "none",
                  backgroundColor: "cyan.300",
                }}
              >
                <Box as="span" fontSize="xl" fontWeight="semibold">
                  Join it's free for now
                </Box>
              </Link> */}
            </>
          }
          media={
            <ScreenShot
              my={12}
              w="580px"
              mx="auto"
              src="static/screenshot-link-tracking.png"
            />
          }
        />

        <Spacer h={32} />
        <Heading fontSize={28} mx="auto" mb={16} display={"flex"}>
          {/* {<AppLogoIcon boxSize={40} ml={7} mb={0.5} />}'s {"    "} Features: */}
          Top Features
          {/* <Box as="span" mx={2} mt={2} fontSize={"3xl"}>
            👇
          </Box> */}
        </Heading>
        <SimpleGrid columns={isSmallScreen ? 1 : 2} spacing={8}>
          <MiniBlock
            title={<>Real-time Notifications</>}
            subtitle={
              <>
                Receive real-time notifications when a viewer has opened your
                content. Get instant feedback via email.
              </>
            }
            media={<NotificationV2 boxSize={28} />}
          />

          <MiniBlock
            title={<>Secure Sharing</>}
            subtitle={
              <>
                Protect your content with a passcode or email authentication,
                Stay in control, and revoke access when needed.
              </>
            }
            media={<SecureSharing boxSize={28} />}
          />
          <MiniBlock
            title={<>Better Versioning</>}
            subtitle={
              <>
                DeckedLink keeps old versions of your content and allows you to
                change your links' associated ones easily.
              </>
            }
            media={<Versioning boxSize={28} />}
          />
          <MiniBlock
            title={<>Privacy-friendly Analytics</>}
            subtitle={
              <>
                We follow Privacy-by-design principles. We only collect what's
                needed. Viewers can be forgotten without damaging your
                analytics.
              </>
            }
            media={<Analytics boxSize={28} />}
          />
        </SimpleGrid>

        <Spacer h={32} />

        <Link
          to="/signup"
          bg={"cyan.100"}
          _hover={{
            textDecoration: "none",
            backgroundColor: "cyan.300",
          }}
          py={2}
          px={6}
          mx="auto"
          rounded="full"
        >
          <HBox
            as="span"
            fontSize="2xl"
            fontWeight="semibold"
            fontFamily="monospace"
          >
            <ArrowRightIcon boxSize={5} m={0} mr={4} />
            Get Started{" "}
          </HBox>
        </Link>

        <Spacer h={32} />
        {/* <PrimaryBlock
          title={<>Keep your focus on the right deals</>}
          subtitle={
            <List spacing={3}>
              <ListItem>
                <ListIcon as={ArrowRightIcon} color="cyan.600" />
                Notion is a great all-in-one tool.
              </ListItem>
              <ListItem>
                <ListIcon as={ArrowRightIcon} color="cyan.600" />
                Manage your customers and prospects in Notion.
              </ListItem>
              <ListItem>
                <ListIcon as={ArrowRightIcon} color="cyan.600" />
                Prepare your <b> proposals</b> and <b>follow-up content </b> in
                Notion pages.
              </ListItem>
              <ListItem>
                <ListIcon as={ArrowRightIcon} color="cyan.600" />
                Use <b>DeckedLink </b> to share content, and easily track
                prospects' engagement and interests.
              </ListItem>
            </List>
          }
          media={<Sales boxSize={"320px"} mx="auto" pr={5} />}
        /> */}
      </Page>
    </LightMode>
  );
}

const EmptyMedia = ({}) => {
  return (
    <VBox h={"280px"} w={"47%"} bg={"gray.500"} ml={"auto"} flexGrow={1}></VBox>
  );
};

const Hero = () => {
  const { isSmallScreen, isMediumScreen } = useSmallScreen();

  return (
    <HBox flexWrap={"wrap-reverse"} w={"100%"} mt={isSmallScreen ? 0 : 10}>
      <VBox
        flexGrow={1}
        flexBasis={isSmallScreen ? "100%" : "50%"}
        p={2}
        align={isSmallScreen ? "center" : "left"}
        textAlign={isSmallScreen ? "center" : "left"}
        maxW="600px"
        mr="auto"
      >
        <Heading
          mb={isSmallScreen ? 3 : 6}
          size={"xl"}
          // fontSize={!(isSmallScreen || isMediumScreen) ? "40px" : "35px"}
          fontSize={"35px"}
          fontWeight={"semibold"}
        >
          Share your engaging content, created in{" "}
          <Box as="span" display="inline-block" mr={2}>
            <NotionIcon mb={2} mr={2} boxSize={8} />
            Notion
          </Box>
        </Heading>
        <Heading
          size={isSmallScreen ? "sm" : "md"}
          color={"gray.600"}
          fontWeight={"semibold"}
          fontFamily={"mono"}
          mb={isSmallScreen ? 1 : 3}
          as={"p"}
        >
          With real-time control and insights.
        </Heading>
        <Heading
          size={isSmallScreen ? "sm" : "md"}
          // fontSize={"20px"}
          color={"gray.600"}
          fontWeight={"medium"}
          fontFamily={"mono"}
          as={"p"}
          lineHeight={"28px"}
        >
          {/* <b>DeckedLink</b> is a <b>privacy-focused</b> tool, we respect viewers
          privacy, and we are <b>GDPR compliant</b>. */}
          {/* <b>DeckedLink</b> follows <b>Privacy by design</b> best practices, and
          respect viewer privacy. */}
          {/* , and is
          GDPR compliant. */}
        </Heading>

        <PrimaryButton
          label={
            <>
              {/* Link your
              <NotionIcon ml={2} mr={1} boxSize={5} /> Notion workspace */}
              Get Early Access
            </>
          }
          to={"/signup"}
          w={"fit-content"}
          mt={10}
          size={"lg"}
          // ml={"auto"}
          mx={0}
        />
      </VBox>
      {/* <EmptyMedia /> */}
      <VBox
        w={"47%"}
        mx={isSmallScreen ? "auto" : 0}
        ml={"auto"}
        flexGrow={1}
        //  border={"1px"}
        maxW={isSmallScreen ? "310px" : "500px"}
      >
        <HeroIllustration
          boxSize={isSmallScreen ? "290px" : "400px"}
          maxH="300px"
          mx="auto"
        />
      </VBox>
    </HBox>
  );
};

export default IndexPage;

// [
//   {
//       "source": "https://docc.art",
//       "target": "https://www.docc.art",
//       "status": "302",
//       "condition": null
//   },
//   {
//       "source": "/<*>",
//       "target": "/index.html",
//       "status": "404-200",
//       "condition": null
//   },
//   {
//       "source": "</^[^.]+$|\\.(?!(css|gif|ico|jpg|js|png|txt|svg|woff|ttf|map|json)$)([^.]+$)/>",
//       "target": "/index.html",
//       "status": "200",
//       "condition": null
//   }
// ]
